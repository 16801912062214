<template>
  <v-app>
<!--    <modal></modal>-->
<!--    <v-content>-->
<!--      <main-layout/>-->
<!--    </v-content>-->
    <router-view></router-view>
  </v-app>
</template>

<script>
import layout from '@/components/layout'
import modal from '@/elements/modal.vue'

export default {
  name: 'App',
  metaInfo() {
    return {
      title: 'Devqode - Outstanding custom development',
      keywords: this.$t('meta_keywords'),
      description: this.$t('meta_description')
    }
  },
  components: {
      'main-layout': layout,
      'modal': modal
  },
  data: () => ({
  }),
};
</script>
